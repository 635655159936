import React, {useRef, useEffect, useState} from 'react'
import Bubble from "../classes/Bubble"
import DataLine from "../classes/DataLine"
import EcoSystemImage from "../images/sm_ecosystem.png"
import EcoSystemImageAvif from "../images/sm_ecosystem.avif"
import WinnerImage from '../images/winner.png'
import WinnerImageAvif from '../images/winner.avif'
import AppStoreImage from '../images/app_store.svg'
import PlayStoreImage from '../images/en_badge_web_generic.png'
import WebAppImage from '../images/web_app_badge.png'
import useWindowDimensions from "../lib/useWindowDimensions"
import styles from '../styles/Home.module.css'
import AnimatedCanvas from "./AnimatedCanvas"

export default () => {
  const canvasRef = useRef(null)
  const { windowWidth } = useWindowDimensions()
  const [ elements, setElements ] = useState([])

  useEffect(() => {
    initElements()
  }, [])

  useEffect(() => {
    initElements()
  }, [windowWidth])

  const canvasWidth = () => (
    Math.max(windowWidth * 0.3, 300)
  )

  const canvasHeight = () => (
    canvasWidth()
  )

  const initElements = () => {
    const ctx = canvasRef.current.getContext('2d')
    let elements = []

    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.2, canvasHeight() * 0.3],
      endPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      interval: 2000,
      delay: 0,
      color: [128, 175, 236]
    }))

    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.5, canvasHeight() * 0.2],
      endPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      interval: 4000,
      delay: 0,
      color: [128, 175, 236]
    }))

    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.8, canvasHeight() * 0.3],
      endPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      interval: 6000,
      delay: 0,
      color: [128, 175, 236]
    }))


    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      endPos: [canvasWidth() * 0.2, canvasHeight() * 0.75],
      interval: 2000,
      delay: 700,
      color: [255, 142, 158]
    }))

    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      endPos: [canvasWidth() * 0.5, canvasHeight() * 0.8],
      interval: 2000,
      delay: 700,
      color: [255, 142, 158]
    }))

    elements.push(new DataLine({
      ctx,
      startPos: [canvasWidth() * 0.5, canvasHeight() * 0.5],
      endPos: [canvasWidth() * 0.8, canvasHeight() * 0.75],
      interval: 2000,
      delay: 700,
      color: [255, 142, 158]
    }))

    /* FROM BOTTOM TO TOP */
    for(let i = 0; i < 10; i ++){
      elements.push(new Bubble({
        ctx,
        speed: 0.0005 + Math.random() * 0.0005,
        x: Math.random() * canvasWidth() * 1.6 + canvasWidth() * 0.2,
        width: canvasWidth() * 0.0075,
        height: canvasHeight() * 0.8,
        length: canvasHeight() * 0.2
      }))
    }
    //elementsRef.current = elements
    setElements(elements)
  }

  const fontSize = () => (
      windowWidth * 0.15 * 0.09
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.stairs} style={{width: canvasWidth()}}>
        <AnimatedCanvas ref={canvasRef}
                        width={canvasWidth()}
                        height={canvasHeight()}
                        elements={elements}
                        style={{position: "absolute", top: 0, left: 0}}
        />
        <picture>
          <source srcSet={EcoSystemImageAvif} type="image/avif"/>
          <img className={styles.stairsImage}
               fetchpriority="high"
               alt="SensorMonitor wireless LoRaWAN eco system"
               src={EcoSystemImage}
               height={canvasHeight()}
               width={canvasWidth()}/>
        </picture>
      </div>
      <div className={styles.slogan} style={{fontSize: Math.max(fontSize() * 3, 20)}}>
        TARVITSETTE TIETOA...<br/>
        ME JÄRJESTÄMME SITÄ!<br/>
        <div className={styles.intro}>
          <div className={styles.introText} style={{fontSize: Math.max(fontSize(), 16), maxWidth: Math.max(windowWidth * 0.15 * 2.6, 512)}}>
            SensorMonitor on seurantajärjestelmä, jonka avulla voidaan seurata oikeastaan mitä vain mitattavissa olevaa asiaa.
            Järjestelmä ja sensorit ovat langattomia, ohjelmistoa on helppo käyttää ja se mukautuu moniin erilaisiin kohteisiin ja tarpeisiin.
            Mittaaminen etänä on kätevää, kustannustehokasta ja toimintavarmaa.
            <p>Kiinnostuitko? Lue lisää ja ota rohkeasti yhteyttä!</p>
          </div>
          <div className={styles.winner}>
            <a target="_blank" href="https://www.businessjoensuu.fi/ajankohtaista/start-me-up-liikeideakilpailun-taso-ennatyksellisen-korkea/" rel="noopener noreferrer">
              <picture>
                <source srcSet={WinnerImageAvif} type="image/avif"/>
                <img
                  src={WinnerImage}
                  width={Math.max(windowWidth * 0.15 * 0.85, 128)}
                  height={Math.max(windowWidth * 0.15 * 0.85, 128)}
                  alt='Business Joensuu - Start Me Up - Winner'
                />
              </picture>
            </a>
          </div>
          <div className={styles.appLinks}>
            <a target="_blank" href="itms-apps://www.apple.com/app/1511418077" rel="noopener noreferrer">
              <img src={AppStoreImage} alt="Apple App Store"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=fi.sensormonitor.generic" rel="noopener noreferrer">
              <img src={PlayStoreImage} alt="Google Play Store"/>
            </a>
            <a target="_blank" href="https://app.sensormonitor.fi" rel="noopener noreferrer">
              <img src={WebAppImage} alt="Web app"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
