import React from 'react'
import styles from '../styles/Cases.module.css'
import RannatImage from '../images/joensuunrannat.png'
import PressureImage from '../images/pressurediff.png'
import MatitaloImage from '../images/matitalographi.png'
import SoundPressureImage from '../images/sound_pressure.png'
import FreezersImage from '../images/freezers.png'
import PeopleCounterImage from '../images/people_counter.png'

export default () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.case}>
        <h1>Case #1 - Rantavesien lämpötilaseuranta</h1>
        <img loading="lazy" width={256} height={256} src={RannatImage} alt="Joensuun uimarantojen vesien lämpötilojen seuranta"/>
        <span>Toteutimme Joensuun kaupungille pilotin, jossa tarkoitus oli mahdollistaa kansalaisille uimarantojen vedenlämpötilojen reaaliaikainen seuranta.
          Tähän projektiin ideoimme poijun sisään integroidun lämpötilasensorin ja toimitimme poijut kaupungille käyttövalmiina.
          Valoimme itse jopa betoniset painot! Lisäksi koodailimme sivun joka näyttää lämpötilat näppärästi karttapohjalla.
          Datat kartalle siirtyvät helposti SensorMonitorin avoimen rajapinnan kautta.
        </span>
      </div>
      <div className={styles.case}>
        <h1>Case #2 - Päiväkodin ilmanvaihto</h1>
        <img loading="lazy" width={256} height={256} src={PressureImage} alt="Päiväkodin ilmanpaineen seuranta"/>
        <span>Toimitimme erään Joensuulaisen päiväkodin ilmanvaihdon säätöä helpottamaan liudan sensoreita.
          Kukin sensori mittaa sisäilman paine-eroa kahden eri huoneen välillä,
          jolloin ilmanvaihtoa säädettäessä nähdään miltei reaaliajassa säätöjen vaikutus kokonaisuuteen.
          Lisäksi ilmanvaihdon toimivuutta on helppo seurata etänä, jolloin tarkastavia mittauskäyntejä ei tarvitse tehdä.
          Kaupanpäälle käteen jää mittaushistoria, jonka avulla voidaan todistaa jälkikäteen, että ilmanvaihto on pelannut kuten piti.
          Selvää säästöä ajankäytössä, mielenrauhassa ja resursseissa siis!
        </span>
      </div>
      <div className={styles.case}>
        <h1>Case #3 - Kalasavustamon laadunvalvonta</h1>
        <img loading="lazy" width={256} height={256} src={MatitaloImage} alt="Mätitalo Oy"/>
        <span>
          Perinteisen Itä-Suomalaisen kalansavustamo <a href="https://matitalo.fi" target="_blank">Mätitalo Oy:n</a> laadunvalvonta piti päivittää vastaamaan tämän päivän vaatimuksia.
          Toimitimme paikalle langattomia lämpötila-antureita, jotka asennettiin kukin tuotantotilan eri huoneisiin. Uudistus mahdollistaa paitsi lämpötilojen historiadatan seuraamisen,
          niin myös tiettyjen raja-arvojen ja hälytysten asettamisen. Sattumalta pian järjestelmän käyttöönoton jälkeen yksi pakastimista alkoi temppuilla ja aiheutti hälytyksen.
          Vika huomattiin näin ajoissa, eikä yhtään kalatuotteita tarvinnut heittää haaskuulle.
        </span>
      </div>
      <div className={styles.case}>
        <h1>Case #4 - Äänenpaineen mittaus</h1>
        <img loading="lazy" width={256} height={256} src={SoundPressureImage} alt="Äänenpaineen mittausta"/>
        <span>
          Yhteistyökumppanimme <a href="https://sensorteam.io" target="_blank">SensorTeam</a> Alankomaista rakentaa erinomaisia mittalaitteita äänenpaineen seurantaan.
          Joensuu innostui pilotoimaan kaupunkiympäristön melun mittausta kyseisen yrityksen tuotteilla.
          Toimittamillamme laitteilla mitataan mm. liikenteen ja erilaisten tapahtumien äänenpaineen desibelitasoja.
          Laitteiden lähettämät tiedot ovat julkisesti nähtävillä <a target="_blank" href="https://jns.sensormonitor.fi">tästä linkistä</a>.
        </span>
      </div>
      <div className={styles.case}>
        <h1>Case #5 - Kylmälaiteseuranta</h1>
        <img loading="lazy" width={256} height={256} src={FreezersImage} alt="Kylmälaiteseuranta"/>
        <span>
          Yliopistojen ja muiden tutkimuslaitoisten pakastimissa säilötään monenlaisia näytteitä, joiden arvo voi olla jopa kymmeniä tuhansia euroja.
          Harvoin kuitenkaan pakastinten lämpötilaseuranta on kunnossa, joten näytteiden pilaantuminen on oikeastaan vain ajan kysymys.
          Ennemmin tai myöhemmin tulee sähkökatko, kompressori hajoaa tai joku jättää oven auki.
          Ratkaisimme nämä ongelmat eräässä kohteessa asentamalla langattomia lämpötila-antureita tärkeimpiin kohteisiin.
          Laitteiden vastuuhenkilöt saavat hälytyksen puhelimeensa heti jos lämpötila nousee liian korkealle liian pitkäksi aikaa.
          Asennus oli nopea ja kustannuksiltaan huokea, joten ymmärrettävästi myös asiakas tyytyväinen!
        </span>
      </div>
      <div className={styles.case}>
        <h1>Case #6 - Kävijämäärien seuranta</h1>
        <img loading="lazy" width={256} height={256} src={PeopleCounterImage} alt="Kävijämäärien seuranta"/>
        <span>
          Kävijämäärien mittaus on tärkeää kun halutaan arvioida esim. julkisten palveluiden käyttöä.
          Perinteisesti tähän on käytetty jos jonkinlaisia tukkimiehen menetelmiä, mutta nykyteknologialla asia hoituu melkolailla vaivattomasti.
          Palvelumme avulla mitataan kävijämääriä esim. kirjastossa, luontopoluilla, liikuntapaikolla ja moottorikelkkareiteillä.
          Kerätyn datan avulla on helppoa arvioida kävijöiden määrää esim. viikonpäivien tai kellonaikojen suhteen.
          Ja jos tilassa järjestetään joku tapahtuma, niin yleisön määrä on välittömästi tiedossa ja tallessa.
          Arvokasta tietoa yhtä kaikki, kun halutaan optimoida asioita myös kustannusten näkökulmasta!
        </span>
      </div>
      <div style={{clear: 'both'}}>&nbsp;</div>
    </div>
  )
}