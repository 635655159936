import React from 'react'
import styles from '../styles/Benefits.module.css'
import AnalyseImage from '../images/analyse.svg'

export default () => {
  return (
    <div className={styles.wrapper}>
      <h2>Etäluettu ja yhteen paikkaan koostettu tieto lisää tuottavuutta</h2>
      <img loading="lazy" className={styles.illustration} src={AnalyseImage} alt="SensorMonitor - tiedon louhintaa"/>
      <p>Langattomat sensorit lähettävät keräämänsä tiedon Digitan LoRaWAN-verkon kautta palvelimellemme, josta sen saa tarkasteltavaksi esim. mobiilisovelluksen avulla. Mittauskäyntejä kohteeseen ei tarvitse enää tehdä ja mittausvirheet vähenevät. Sensoreiden keräämä data ohjataan yhdelle alustalle, josta tietoa voi tulkita oman käyttäjätunnuksen takaa. Yhteen paikkaan koostettu tieto poistaa turhaa työtä.</p>
      <h4>Tietoturvallinen ja toimintavarma</h4>
      <p>Verrattuna suoraan lähiverkon kautta Internetiin yhdistettäviin sensoreihin (tai IoT-laitteisiin ylipäänsä) käyttämämme LoRaWAN-laitteisto on tietoturvallisempaa. Tämä siksi, että laitteiden käyttäessä eri verkkoa kuin esim. kodin tietokoneet, ei hakkeri voi käyttää laitteita porttina päästäkseen käsiksi niihin. Lisäksi käyttämämme laitteet ovat immuuneja sähkökatkoille, koska ovat paristo-/akkukäyttöisiä eivätkä tarvitse Internet-yhteyttä toimiakseen. Rakennus voi pimentyä, mutta sensoreista saadaan kyllä tietoa ulos!</p>
      <h4>Yhdistä jo käytössä olevat sensorit ja alustat</h4>
      <p>Järjestelmäämme voi liittää monien eri valmistajien sensoreita ja järjestelmästä voidaan lähettää tietoa myös toisiin alustoihin. Voit käyttää ja laajentaa mahdollisesti jo olemassa olevaa mittauslaitteistoasi.</p>
      <h4>Sensorit käteen -palvelu</h4>
      <p>Tuotamme palvelumme pääasiassa “sensorit käteen” mallilla. Suunnittelemme mittausvälineistön tarpeesi mukaan, alustamme ohjelmiston ja toimitamme käyttövalmiit sensorit sinulle asennettavaksi.</p>
      <h4>Yllättävän edullinen</h4>
      <p>Mittausjärjestelmien kustannukset putoavat keskitetyn alustan ja langattomien sensoreiden avulla huomattavasti, kun etäällä sijaitseville sensoreille ei tarvitse järjestää kaapelointia/nettiyhteyttä/mittauskäyntejä ja käytössä on vain yksi alusta. Yksinkertaisimmat mittaus- ja valvontajärjestelmät kauttamme liikkuvat alle tuhannen euron hintaluokassa.</p>
      <h4>Järjestelmän tuomia etuja</h4>
      <ul>
        <li>Valvoo reaaliaikaisesti ja jatkuvasti</li>
        <li>Hälytykset teksti-, push- tai sähköpostiviestillä</li>
        <li>Ennaltaehkäisee ongelmatilanteita</li>
        <li>Mahdollistaa mittaushistorian tarkastelun</li>
        <li>Tietoa voidaan siirtää myös muihin järjestelmiin</li>
        <li>Etävalvonta säästää resursseja</li>
        <li>Vähentää kasvihuonekaasupäästöjä</li>
      </ul>
      <h4>Esimerkkejä käyttökohteista</h4>
      <ul>
        <li>Datan kerääminen</li>
        <li>Pumppaamoiden, kaivojen, siilojen, roskamolokkien yms. täyttöasteseurannat</li>
        <li>Ilmanlaadun (lämpötila, kosteus, CO2, VOC, pm) seuranta sisällä ja ulkona</li>
        <li>Kävijämääräseuranta sisällä ja ulkona</li>
        <li>Ilmanpaine-erojen mittaus apuna ilmanvaihdon säätämisessä</li>
        <li>Kylmälaitteiden sisälämpötilan seuranta ja hälytykset</li>
        <li>Erinäiset kosteusmittaukset</li>
        <li>Rakentamisen olosuhdevalvonta</li>
        <li>Äänenpaineen mittaus</li>
        <li>Tuotantoprosessin seuranta</li>
        <li>Palohälyttimien etäseuranta</li>
        <li>Logistiikan seuranta</li>
        <li>Edes mielikuvitus ei ole rajana, kerro mittaustarpeesi niin etsimme tai rakennamme oikean ratkaisun!</li>
      </ul>
    </div>
  )
}